import React from "react";
import { useNavigate } from "react-router-dom";
import logoBlancoMob from '../../../assets/images/svgs/logoBlancoMob.svg';
import logoWeCoverBlancoMob from '../../../assets/images/svgs/logoWeCoverBlancoMob.svg';
import PBOLogo from "../../../assets/images/svgs/logoBlanco.svg";
import { socialLinks } from "../../../data";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";
import { GetCountryUrl } from "../../../util/GetCountryUrl";
import { GetGlobalParams } from "../../../util/GetGlobalParams";
import { setRefCategory, setRefQuestion } from "../../../actions";
import { connect } from "react-redux";
import logo_superintendencia from '../../../assets/images/svgs/logo_superintendencia.svg'
import { ReactSVG } from 'react-svg';

let isTabletOrMobile = false;
const Footer = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const navigate = useNavigate();

  const iconSize = 32

  return (
    <div className="Footer-container">
      <div className="Footer-container-wrap">
        <div className="Footer-container-wrap-main">
          <div className="Footer-container-wrap-content">
            <div className="Footer-container-wrap-content-links">
              <div className="Footer-container-wrap-content-links-logos">
                <img src={logoBlancoMob} alt="pbo logo" className="Footer-container-wrap-content-links-logo" onClick={() => navigate("/inicio")}/>
                <img src={logoWeCoverBlancoMob} alt="pbo logo" className="Footer-container-wrap-content-links-logo" onClick={() => navigate("/inicio")}/>
              </div>
              <div className="Footer-container-wrap-content-links-list">
                {socialLinks?.map((redSocial: any, index: any) => (
                  <a key={index} href={redSocial.link} target="_blank" rel="noreferrer">
                    <ReactSVG 
                        src={redSocial.svgC}
                        style={{marginRight: 16}}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + iconSize);
                            svg.setAttribute('style', 'width: ' + iconSize);
                        }}
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Menú</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/")}>Home</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefCategory(true)
                }}
              >Cotizá tu cobertura</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefQuestion(true)
                }}
              >Preguntas frecuentes</b>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/contacto")}>Contactanos</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/login")}>Ingresar</b>
            </div>
          </div>
          <div className="Footer-container-wrap-info">
            <div className="Footer-container-wrap-info-rights">
              {isTabletOrMobile ?
                <b className="Footer-container-wrap-info-rights-law regular medium">WeCover © {new Date().getFullYear()} - Todos los derechos reservados</b>
                :
                <b className="Footer-container-wrap-info-rights-law regular small">WeCover © {new Date().getFullYear()} - Todos los derechos reservados</b>
              }
              <div className="Footer-container-wrap-info-rights-power">
                {isTabletOrMobile ?
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular medium">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular medium" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                  :
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular small">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular small" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-container-legals">
        <div className="Footer-container-legals-wrap">
          <div className="Footer-container-legals-data">
            <div className="Footer-container-legals-data-first">
              <div className="Footer-container-legals-data-first-noColorCont">
                <p className="Footer-container-legals-data-first-noColorCont-noColor">N° de matrícula SSN<br/>1515</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-noColorCont-noColor">Departamento de Orientación y<br/>Asistencia al Asegurado - DOAA</p>
              </div>
              <div className="Footer-container-legals-data-first-colorCont">
                <p className="Footer-container-legals-data-first-colorCont-color">0800-666-8400</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-colorCont-color">www.argentina.gob.ar/ssn</p>
              </div>
            </div>
            <div className="Footer-container-legals-data-superImg">
              <img className="Footer-container-legals-data-superImg-img" src={logo_superintendencia}/>
            </div>
          </div>
          <p className="Footer-container-legals-publicidad">WECOVER CUIT 30-71581963-1 N° de matrícula 1515, ofrece y comercializa BHN VIDA S.A. CUIT, 30-69350395-3. Nº de inscripción 695 SSN - Dirección Rivadavia 611 piso 10 - (c1002aae) - CABA. Teléfono atención al asegurado 0810-666-0066. Coberturas y costos sujetos a los términos y condiciones técnico-contractuales aprobados por la Superintendencia de Seguros de la Nación. La compañía de seguros dispone de un servicio de atención al asegurado que atenderá las consultas y reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derechohabientes. En caso de que el reclamo no haya sido resuelto o que haya sido denegada su admisión o desestimado, total o parcialmente, podrá comunicarse con la Superintendencia de Seguros de la Nación por teléfono al 0800 666 8400, correo electrónico a consultas@ssn.gob.ar o por formulario web o a BHN Seguros generales al site web ut supra mencionado.</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
      countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  setRefCategory,
  setRefQuestion
})(Footer);