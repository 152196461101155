import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  toggleErrorOff,
  userAccessTokenSet,
  getUserAuth,
  saveDeviceUsed
} from './actions'

import './App.css'

import { GlobalStyles } from "./components/generalComponents/globalStyles/globalStyles.js";
import { countries } from './data';
import { GetGlobalParams } from './util/GetGlobalParams';
import loading from './assets/lotties/loading.json'
import Lottie from 'react-lottie';
import Cookies from 'universal-cookie';

//PAGES
import DemoStart from './pages/demoStart/DemoStart';

import Inicio from './pages/inicio/Inicio'
import Cotizar from './pages/cotizar/Cotizar';
import Oferta from './pages/oferta/Oferta';
import Contact from './pages/contact/Contact';
import ContactExito from './pages/contactExito/ContactExito';
import Checkout from './pages/checkout/Checkout';
import CheckoutFin from './pages/checkoutFin/CheckoutFin';
import CheckoutFinAdmin from './pages/checkoutFinAdmin/CheckoutFinAdmin';

import Login from './pages/login/Login';
import PasswordRecovery from './pages/passwordRecovery/PasswordRecovery'
import PasswordRecoveryCode from './pages/passwordRecoveryCode/PasswordRecoveryCode'
import PasswordRecoveryForm from './pages/passwordRecoveryForm/PasswordRecoveryForm'
import PasswordRecoveryFinish from './pages/passwordRecoveryFinish/PasswordRecoveryFinish'

import Home from './pages/home/Home';
import DetailsPolicy from './pages/detailsPolicy/DetailsPolicy';
import ChangePassword from './pages/changePassword/ChangePassword';
import ChangePasswordSuccess from './pages/changePassword/changePasswordSuccess/ChangePasswordSuccess';
import PerfilHome from './pages/perfilHome/PerfilHome';
import MisReclamos from './pages/misReclamos/MisReclamos';
import MisCoberturas from './pages/misCoberturas/MisCoberturas';

//ROUTE TYPES
import ProtectedRoute from './components/routeComponents/ProtectedRoute';
import PublicRoute from './components/routeComponents/PublicRoute';

//REDUX TYPES
import { 
  USER_DATA,
  USER_LOADER
} from './actions/types';

//OTROS
import ErrorComponent from './components/generalComponents/ErrorComponent/ErrorComponent';
import BurgerMenu from './components/generalComponents/BurgerMenu/BurgerMenu';
import Circulacion from './pages/Circulacion/Circulacion';

let cookiesUA = new Cookies();

const App = (props: any) => {
  const dispatch = useDispatch();
  const userLoader = useSelector((state: any) => state.user.userLoader);
  const [lang, setLang] = useState<any>(undefined);
  const [stateloading, setStateLoading] = useState<boolean>(false);
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
  const [globalFontFamily, setGlobalFontFamily] = useState<any>(process.env.REACT_APP_FONT_FAMILY);

  const sendMessageToParent = () => {
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('Give', appBaseIframe)
    } return;
  } 

  const recieveMessageFromParent = () => {
    window.addEventListener("message", (event) => {
      if(window.location.hostname != 'localhost') {
        if (event.origin !== appBaseIframe) return;
        if (event.data?.includes('Token:')) {
          const regex = /Token:\s*(.+)/;
          const match = event.data?.match(regex);
          props.userAccessTokenSet(match[1]);
        }
      } return;
    }, false);
  }

  const generateDeviceUsed = () => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi/i.test(userAgent);
    const isTablet = /Tablet/i.test(userAgent);
    
    let deviceType;
    if (isMobile) {
      deviceType = 'Mobile';
    } else if (isTablet) {
      deviceType = 'Tablet';
    } else {
      deviceType = 'Desktop';
    }

    props.saveDeviceUsed(deviceType, 'web')

  }

  useEffect(() => {
    sendMessageToParent();
    recieveMessageFromParent();
    generateDeviceUsed();
    loadLanguage(1);
  }, []) 

  useEffect(() => {
    let cookies = cookiesUA.get('userAccessToken');
    if (cookies) {
      props.userAccessTokenSet(cookies);
      dispatch(getUserAuth());
    } else {
      dispatch({ type: USER_DATA, payload: null });
      dispatch({ type: USER_LOADER, payload: true })
    }
    GetGlobalParams();
  }, []);

  const loadLanguage = (country: number) => {
    countries.find(c => c.id === country)?.lang().then((messages) => {
      setLang(messages);
    });
  };

  const errorModal = () => {
    if(props.errorMessage) {
      return (
        <div className="coverLoading">
          <ErrorComponent
            errorMessage={props.errorMessage}
            toggleErrorOff={() => props.toggleErrorOff()}
          />
        </div>
      )
    }
  }

  const showLoading = (show: boolean) => {
    if(show) {
      return (    
        <div className="coverLoading">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loading,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={120}
            width={120}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) 
    } return null;
  }

  const suspenseLoading = () => {
    return showLoading(true);
  }
  const checkLoading = () => {
    return showLoading(props.loading || stateloading || (props.countryId !== undefined && lang === undefined));
  }

  const getAllRoutes = () => {
    if (lang === undefined) return <></>;

    return (
      <React.Fragment>
        <Routes>
          <Route path="/inicio" element={<Inicio/>} />
          <Route path="/cotizar" element={<Cotizar/>} />
          <Route path="/oferta" element={<Oferta/>} />
          <Route path="/contacto" element={<Contact/>} />
          <Route path="/contacto-exito" element={<ContactExito/>} />
          <Route path="/checkout" element={<Checkout setLoading={setStateLoading}/>} />
          <Route path="/checkout-fin" element={<CheckoutFin/>} />
          <Route path="/checkout-fin-admin" element={<CheckoutFinAdmin/>} />
          <Route path="/recovery" element={<PasswordRecovery/>} />
          <Route path="/recovery-code" element={<PasswordRecoveryCode/>} />
          <Route path="/recovery-code-form" element={<PasswordRecoveryForm/>} />
          <Route path="/recovery-code-form-finish" element={<PasswordRecoveryFinish/>} />
          <Route element={<PublicRoute/>}>
            <Route path="/login" element={<Login/>} />
          </Route>
          <Route element={<ProtectedRoute/>}>
            <Route path="/home" element={<Home/>} />
            <Route path="/policy/:id" element={<DetailsPolicy/>} />
            <Route path="/circulacion/:id" element={<Circulacion userData={props.userData}/>} />
            <Route path="/change-password" element={<ChangePassword/>} />
            <Route path="/change-password-success" element={<ChangePasswordSuccess/>} />
            <Route path="/perfil" element={<PerfilHome/>} />
            <Route path="/reclamos" element={<MisReclamos/>} />
            <Route path="/mis_coberturas" element={<MisCoberturas/>} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} />}/>
        </Routes>
      </React.Fragment>
    )
  }

  return (
    <IntlProvider locale={"es"} messages={lang} onError={(e) => {}}>
      <GlobalStyles />
      <div style={{ fontFamily: globalFontFamily }}>
        {checkLoading()}
        {errorModal()}
        {userLoader ?
          <Router>
            <React.Fragment>
              <BurgerMenu changeColor={window.location.pathname === '/checkout'} />
              <div
                className="burgerMenu-container-outer"
                style={{ opacity: props.burgerMenuOpen ? 1 : 0, zIndex: props.burgerMenuOpen ? 10000 : -1 }}
              ></div>
              <React.Suspense fallback={suspenseLoading()}>
                <Routes>
                  <React.Fragment>
                    <Route path="/" element={<DemoStart setGlobalFontFamily={setGlobalFontFamily} />} />
                    <Route path="/*" element={getAllRoutes()} />
                  </React.Fragment>
                </Routes> 
              </React.Suspense>
            </React.Fragment>
          </Router>
        : null}
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    loading: state.general.loading,
    errorMessage: state.general.errorMessage,
    burgerMenuOpen: state.general.burgerMenuOpen,
    userAccessToken: state.general.userAccessToken,
    userData: state.user.userData
  };
};
 

export default connect(mapStateToProps, {
  toggleErrorOff,
  userAccessTokenSet,
  getUserAuth,
  saveDeviceUsed
})(App);