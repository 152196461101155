
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './FaqSection.css'
import AccordionCotizar from '../../../components/AccordionCotizar/AccordionCotizar';
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const FaqSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const navigate = useNavigate();

  const faq = [
    {
      'name': '¿Para qué me sirve el seguro de Salud Integral?',
      'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante el primer diagnóstico de cáncer, infarto de miocardio, derrame e infarto cerebral, cirugía o un trasplante te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a>Conocé más sobre Salud Integral</a>',
      onClick: () => {
        props.categoriaSelectedChange(9681)
        const adminRoute = props.admin ? '&admin=True' : ''
        navigate('/oferta?productId=9681' + adminRoute)
      }
    },
    {
      'name': '¿Para qué me sirve el seguro de Salud Mujer?',
      'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante distintas intervenciones quirúrgicas, el primer diagnóstico de cáncer, gastos para adquirir drogas oncológicas o un trasplante te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a>Conocé más sobre Salud Mujer</a>',
      onClick: () => {
        props.categoriaSelectedChange(9682)
        const adminRoute = props.admin ? '&admin=True' : ''
        navigate('/oferta?productId=9682' + adminRoute)
      }
    },
    {
      'name': '¿Para qué me sirve el seguro de Salud Senior?',
      'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante intervenciones quirúrgicas, prótesis, cuidados en el hogar o insuficiencia renal crónica te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a>Conocé más sobre Salud Senior</a>',
      onClick: () => {
        props.categoriaSelectedChange(9683)
        const adminRoute = props.admin ? '&admin=True' : ''
        navigate('/oferta?productId=9683' + adminRoute)
      }
    }
  ]
  
  return (
    <div className="faqSection-container" ref={props.questionSection}>
      <h4 className="faqSection-container-title bold" >Me gustaría saber...</h4>
      <div id='#faq' className="faqSection-container-list">
        {faq?.map((a: any, index: number) => (
          <AccordionCotizar
            id={a.name + ' faq'}
            key={index}
            title={a.name}
            text={a.description}
            onClick={() => a?.onClick()}
          />
        ))}
      </div>
    </div>
  );
}

export default FaqSection;