import {
    CHECKOUT_DATA,
    LOADING,
    CHECK_OUT_PRODUCT_REQUIREMENTS,
    CHECK_OUT_PAYMENT_OPTIONS,
    USER_CREDIT_CARDS,
    CARD_SELECTED_CHECKOUT,
    CVV_TEXT_INPUT,
    CHECKOUT_DATA_PAYMENT_INFO,
    PROMO_CODE_ERROR,
    CHECK_OUT_CONFIRM_START,
    CHECK_OUT_CONFIRM_SUCCESS,
    CHECK_OUT_CONFIRM_ERROR,
    CHECK_OUT_CONFIRM_ERROR_MESSAGE,
    CHECK_OUT_CONFIRM_SUCCESS_PRODUCT,
    CHECK_OUT_CONFIRM_SUCCESS_USER,
    FETCH_TYPES_BANKS_ARRAY,
    BANCO_TARJETA_ID,
    TIPO_TARJETA_ID,
    FETCH_BANK_ARRAY,
    DNI_TARJETA,
    NUMERO_TARJETA,
    TITULAR_TARJETA,
    FECHA_TARJETA,
    SUCCESS_CARD_TEXT,
    STEP_TRES,
    USER_PRODUCTS_ACTIVE,
    CHECK_OUT_CONFIRM_DATA,
    CHECK_OUT_CBU,
    CHECK_OUT_NUMERO_TARJETA,
    CHECK_OUT_TIPO,
    CHECK_OUT_EMISORA_TYPE,
    CHECK_OUT_REFERER,
    CHECKOUT_DATA_USER,
    CHECK_OUT_REFERER_APPLIED,
    CHECK_OUT_REFERER_ERROR,
    CHECKOUT_DATA_HOME,
    USER_DATA
} from './types';
import {
    netCheckOutOfferById,
    netCheckOutRequerements,
    netCheckOutPaymentOptions,
    getCreditCardUserById,
    netCheckOutProductInfo,
    netCheckOutPaymentFinish,
    netGetUserCreditCardInfo,
    netCheckOutDiscount,
    netCheckOutDiscountDelete,
    netCheckOutConfirm,
    netCheckOutStatus,
    getBanks,
    getBrands,
    postCreditCard,
    netCheckoutLink,
    netSendUbiPagosData,
    getInsuredUser,
    netSaveCbuCheckout,
    netCheckOutPolicyHolder,
    netGetPatData,
    netAddPatChile,
    netCheckRefererAvailability,
    netCheckOutOfferHomeById,
    netPolicyInfo,
    netUserCbuInfo,
    getUserMine,
    netCarfacilUserData,
    netCarfacilAvtividades,
    getPardoData,
    getCivilTypes,
    getSexTypes,
    netCheckUserExistance,
    netGetLocalidades,
    netDownloadPolicyMA,
    netDownloadPolicySC,
    netGetQuoteQuestions
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';
import { saveAs } from 'file-saver';

export const changeCbuText = (value: string) => {
    return {
        type: CHECK_OUT_CBU,
        payload: value
    };
};

export const changeNumeroTarjetaText = (value: string) => {
    return {
        type: CHECK_OUT_NUMERO_TARJETA,
        payload: value
    };
};

export const changeTipoText = (value: any) => {
    return {
        type: CHECK_OUT_TIPO,
        payload: value
    };
};

export const changeEmisoras = (value: any) => {
    return {
        type: CHECK_OUT_EMISORA_TYPE,
        payload: value
    };
};

export const actionCheckAdminSucursal = (setIsAdminSucursal: any) => {
    return (dispatch: any) => {
        axios.get(getUserMine())
            .then((response) => {
                setIsAdminSucursal(response?.data?.role?.name === "SUCURSAL" || response?.data?.role?.name === "ADMINISTRADOR")
            })
            .catch((error) => {

            })
    }
}

export const checkOutOfferByIdForUser = (id: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netCheckOutOfferById(id))
            .then((response) => {
                dispatch({ type: CHECKOUT_DATA_USER, payload: response.data });
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const checkOutOfferHomeById = (id: any, setCheckoutData: any) => {
    axios.get(netCheckOutOfferHomeById(id))
        .then((response) => {
            setCheckoutData(response.data);
        })
        .catch((error) => {
        })
}

export const getPolicyInfo = (id: any, setPolicy: any) => {
    axios.get(netPolicyInfo(id))
        .then((response) => {
            setPolicy(response.data)
        })
        .catch((error) => {

        })
}

export const actionDownloadPolicyMA = (id: string, setDownloading: any) => {
    setDownloading(true);
    axios.get(netDownloadPolicyMA(id))
        .then((response) => {
            window.open(response.data?.url, '_blank')
            setDownloading(false);
        })
        .catch((error) => {
            setDownloading(false);
        })
}

export const actionDownloadPolicySC = (id: any, setDownloading: any) => {
    setDownloading(true)
    axios.get(netDownloadPolicySC(id), { responseType: 'blob' }) // Set responseType to 'blob'
        .then((responsePoliza) => {
            const blob: Blob = responsePoliza.data; // Access the blob data
            const blobUrl = URL.createObjectURL(blob);

            saveAs(blob, 'file.pdf'); // Use saveAs from file-saver to save the blob as a file
            setDownloading(false);
        })
        .catch((error) => {
            setDownloading(false);
        });
};

export const checkOutOfferById = (id: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netCheckOutOfferById(id))
            .then((response) => {
                dispatch({ type: CHECKOUT_DATA, payload: response.data });
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const changeStep = (index: number, checkOutData: any) => {
    return (dispatch: any) => {
        const newData = JSON.parse(JSON.stringify(checkOutData))
        newData.steps[index].status = 'PENDING'
        dispatch({ type: CHECKOUT_DATA, payload: newData });
    }
}

export const getPatData = (checkOutId: any, setPatChileData: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netGetPatData(checkOutId))
            .then(response => {
                setPatChileData(response.data)
                dispatch({ type: LOADING, payload: false });
            }).catch(error => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            });
    }
}

export const checkOutRequerements = (id: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netCheckOutRequerements(id)).then(response => {
            dispatch({ type: CHECK_OUT_PRODUCT_REQUIREMENTS, payload: response.data });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    };
}

export const getDataForCheckoutPayment = (checkOutData: any, setExistingPaymentSelected: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckOutPaymentOptions(checkOutData?.id),
            method: 'get',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
        }).then(responsePay => {
            dispatch({ type: CHECK_OUT_PAYMENT_OPTIONS, payload: responsePay.data });
            axios.get(netGetQuoteQuestions(checkOutData?.offer?.product?.categoryId, checkOutData?.offer?.quoteId))
            .then((response) => {
                console.log(response.data)
                dispatch({ type: USER_CREDIT_CARDS, payload: [] });
                    axios.get(getCreditCardUserById(checkOutData?.policyHolder?.id)).then(response => {
                        if (response.data !== null) {
                            response.data.forEach((card) => {
                                let fullNro = card.nroTarjeta.toString();
                                card['lastFour'] = fullNro.substr(fullNro.length - 4);
                                card['lastFive'] = fullNro.substr(fullNro.length - 5);
                            });
                            dispatch({ type: USER_CREDIT_CARDS, payload: response.data });
                        }
                        dispatch({ type: LOADING, payload: false });
                    }).catch(error => {
                        dispatch({ type: LOADING, payload: false });
                        genericErrorHandler(error)
                    });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const checkOutPayment = (id: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckOutPaymentOptions(id),
            method: 'get',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
        }).then(responsePay => {
            dispatch({ type: CHECK_OUT_PAYMENT_OPTIONS, payload: responsePay.data });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const fetchUserCardsBrands = (userId: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        dispatch({ type: USER_CREDIT_CARDS, payload: [] });
        axios.get(getCreditCardUserById(userId)).then(response => {
            if (response.data !== null) {
                response.data.forEach((card) => {
                    let fullNro = card.nroTarjeta.toString();
                    card['lastFour'] = fullNro.substr(fullNro.length - 4);
                    card['lastFive'] = fullNro.substr(fullNro.length - 5);
                });
                dispatch({ type: USER_CREDIT_CARDS, payload: response.data });
            }
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    };
};

export const empytUserCards = () => {
    return (dispatch: any) => {
        dispatch({ type: USER_CREDIT_CARDS, payload: [] });
    }
}

export const checkOutProduct = (id: any, data: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckOutProductInfo(id),
            method: 'put',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
            data: data
        })
            .then(response => {
                dispatch({ type: CHECKOUT_DATA, payload: response.data });
                dispatch({ type: LOADING, payload: false });
            })
            .catch(error => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            });
    };
}

export const cardSelected = (value: number) => {
    return {
        type: CARD_SELECTED_CHECKOUT,
        payload: value
    };
}

export const cvvTextInputChange = (text: string) => {
    return {
        type: CVV_TEXT_INPUT,
        payload: text
    };
}

export const fetchCardBanks = (brandId: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(getBanks(brandId)).then(response => {
            let aux = response.data
            aux.sort(function (a: any, b: any) {
                if (a.descripcion < b.descripcion) { return -1; }
                if (a.descripcion > b.descripcion) { return 1; }
                return 0;
            })
            aux = aux.filter((f: any) => f.descripcion !== 'Otro');
            dispatch({ type: FETCH_TYPES_BANKS_ARRAY, payload: aux });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    };
}

export const getUserCreditCardInfo = (creditCardId: number, userId: any, setPaymentData: any) => {
    axios(netGetUserCreditCardInfo(creditCardId, userId)).then(response => {
        setPaymentData(response.data);
    }).catch(error => {
    });
}

export const getUserCbuInfo = (cbuId: number, setPaymentData: any) => {
    axios(netUserCbuInfo(cbuId)).then(response => {
        setPaymentData(response.data);
    }).catch(error => {
    });
}

export const checkOutDiscount = (text: string, id: string) => {
    return (dispatch: any) => {
        dispatch({ type: PROMO_CODE_ERROR, payload: '' })
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckOutDiscount(id),
            method: 'put',
            headers: {
                'accept': '*/*',
                'Content-Type': 'text/plain',
            },
            data: text
        }).then(response => {
            dispatch({ type: CHECKOUT_DATA, payload: response.data });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            let message = "";
            if (error?.response?.status === 400 && error?.response?.data?.message != undefined) {
                message = error.response.data.message;
            } else {
                message = 'Codigo inválido';
            }
            dispatch({ type: PROMO_CODE_ERROR, payload: message })
            dispatch({ type: LOADING, payload: false });
        });
    };
}

export const promoCoceErrorToggle = (text) => {
    return {
        type: PROMO_CODE_ERROR,
        payload: text
    }
}

export const changeReferer = (value: any) => {
    return {
        type: CHECK_OUT_REFERER,
        payload: value
    }
}

export const changeRefererApplied = (value: any) => {
    return {
        type: CHECK_OUT_REFERER_APPLIED,
        payload: value
    }
}

export const checkRefererAvailability = (dni: string) => {
    return (dispatch: any) => {
        dispatch({ type: CHECK_OUT_REFERER_ERROR, payload: false });
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckRefererAvailability(dni),
            method: 'get',
        }).then(response => {
            if(response.data) {
                dispatch({ type: CHECK_OUT_REFERER, payload: dni });
                dispatch({ type: CHECK_OUT_REFERER_APPLIED, payload: true });
            } else {
                dispatch({ type: CHECK_OUT_REFERER, payload: dni });
                dispatch({ type: CHECK_OUT_REFERER_APPLIED, payload: false });
                dispatch({ type: CHECK_OUT_REFERER_ERROR, payload: true });
            }
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const checkOutDiscountDelete = (id: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netCheckOutDiscountDelete(id),
            method: 'delete',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            dispatch({ type: CHECKOUT_DATA, payload: response.data });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const checkOutPaymentAndConfirm = (payment: any, referer: any, id: string, productName: string, user: string, navigate: any, countryId: any, admin: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: true });
        axios({
            url: netCheckOutPaymentFinish(id),
            method: 'put',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
            data: payment
        })
            .then(() =>  {
                axios({
                    method: 'POST',
                    url: netCheckOutConfirm(id),
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json',
                    },
                    params: {referer: referer}
                }).then(responseCon => {
                    const checkOutStatus = () => {
                        axios(netCheckOutStatus(id)).then(responseStat => {
                            if (responseStat.data.status === "FINISHED" || responseStat.data.status === "PENDING_REQUEST" || responseStat.data.status ===  "INCOMPLETED_PENDING_REQUEST") {
                                clearInterval(refreshIntervalId);
                                dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: true });
                                setTimeout(() => {
                                    dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
                                    dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_PRODUCT, payload: productName });
                                    dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_USER, payload: user });
                                    dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                    if(admin) {
                                        navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Exito')
                                    } else {
                                        navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Exito')
                                    }
                                    axios.get(getInsuredUser())
                                        .then(response => {
                                            dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                                        }).catch(error => {
                                        });
                                }, 2500);
                            } else {
                                if (responseStat.data.status === "FAILED_EMISSION") {
                                    clearInterval(refreshIntervalId);
                                    dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                    setTimeout(() => {
                                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: responseStat.data.next.config.error });
                                        dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                        if(admin) {
                                            navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Error')
                                        } else {
                                            navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Error')
                                        }
                                    }, 2500);
                                }
                            }
                        })
                            .catch(error => {
                                clearInterval(refreshIntervalId);
                                dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                setTimeout(() => {
                                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                                    genericErrorHandler(error)
                                }, 2500);
                            })
                    }
                    var refreshIntervalId = setInterval(checkOutStatus, 5000);
                }).catch(error => {
                    dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                    setTimeout(() => {
                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                        genericErrorHandler(error)
                    }, 2500);
                });
            })
            .catch((error) => {
                dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                setTimeout(() => {
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                    genericErrorHandler(error)
                }, 2500);
            })
    }
}

export const checkOutAddCreditCardAndConfirm = (data: any, userId: any, paymentInfo: any, referer: any, id: string, cvv: string, productName: string, user: string, originalParams: any, navigate: any, countryId: any, admin: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: true });
        axios({
            method: 'POST',
            url: postCreditCard(userId),
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: data
        })
            .then((response) => {
                const paymentInfoData = {
                    id: response?.data?.id,
                    paymentType: paymentInfo?.paymentType
                }
                axios({
                    url: netCheckOutPaymentFinish(id),
                    method: 'put',
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json'
                    },
                    data: paymentInfoData
                })
                    .then(() =>  {
                        axios({
                            method: 'POST',
                            url: netCheckOutConfirm(id),
                            headers: {
                                'accept': '*/*',
                                'Content-Type': 'application/json',
                            },
                            params: {referer: referer}
                        }).then(responseCon => {
                            const checkOutStatus = () => {
                                axios(netCheckOutStatus(id)).then(responseStat => {
                                    if (responseStat.data.status === "FINISHED" || responseStat.data.status === "PENDING_REQUEST" || responseStat.data.status ===  "INCOMPLETED_PENDING_REQUEST") {
                                        clearInterval(refreshIntervalId);
                                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: true });
                                        setTimeout(() => {
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_PRODUCT, payload: productName });
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_USER, payload: user });
                                            dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                            if(admin) {
                                                navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Exito')
                                            } else {
                                                navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Exito')
                                            }
                                            axios.get(getInsuredUser())
                                                .then(response => {
                                                    dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                                                }).catch(error => {
                                                });
                                        }, 2500);
                                    } else {
                                        if (responseStat.data.status === "FAILED_EMISSION") {
                                            clearInterval(refreshIntervalId);
                                            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                            setTimeout(() => {
                                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: responseStat.data.next.config.error });
                                                dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                                if(admin) {
                                                    navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Error')
                                                } else {
                                                    navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Error')
                                                }
                                            }, 2500);
                                        }
                                    }
                                })
                                    .catch(error => {
                                        clearInterval(refreshIntervalId);
                                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                        setTimeout(() => {
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                                            genericErrorHandler(error)
                                        }, 2500);
                                    })
                            }
                            var refreshIntervalId = setInterval(checkOutStatus, 5000);
                        }).catch(error => {
                            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                            setTimeout(() => {
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                                genericErrorHandler(error)
                            }, 2500);
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                        setTimeout(() => {
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                            genericErrorHandler(error)
                        }, 2500);
                    })
            })
            .catch((error) => {
                dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                setTimeout(() => {
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                    genericErrorHandler(error)
                }, 2500);
            })
    }
}

export const checkOutAddCbuAndConfirm = (cbuText: string, userId: any, paymentInfo: any, referer: any, id: string, cvv: string, productName: string, user: string, originalParams: any, navigate: any, countryId: any, admin: boolean) => {
    return (dispatch: any) => {
        const data = {
            'accountBankId': cbuText
        }
        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: true });
        axios({
            method: 'POST',
            url: netSaveCbuCheckout(userId),
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: data
        })
            .then((response) => {
                const paymentInfoData = {
                    id: response?.data?.id,
                    paymentType: paymentInfo?.paymentType
                }
                axios({
                    url: netCheckOutPaymentFinish(id),
                    method: 'put',
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json'
                    },
                    data: paymentInfoData
                })
                    .then(() =>  {
                        axios({
                            method: 'POST',
                            url: netCheckOutConfirm(id),
                            headers: {
                                'accept': '*/*',
                                'Content-Type': 'application/json',
                            },
                            params: {referer: referer}
                        }).then(responseCon => {
                            const checkOutStatus = () => {
                                axios(netCheckOutStatus(id)).then(responseStat => {
                                    if (responseStat.data.status === "FINISHED" || responseStat.data.status === "PENDING_REQUEST" || responseStat.data.status ===  "INCOMPLETED_PENDING_REQUEST") {
                                        clearInterval(refreshIntervalId);
                                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: true });
                                        setTimeout(() => {
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_PRODUCT, payload: productName });
                                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_USER, payload: user });
                                            dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                            if(admin) {
                                                navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Exito')
                                            } else {
                                                navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Exito')
                                            }
                                            axios.get(getInsuredUser())
                                                .then(response => {
                                                    dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                                                }).catch(error => {
                                                });
                                        }, 2500);
                                    } else {
                                        if (responseStat.data.status === "FAILED_EMISSION") {
                                            clearInterval(refreshIntervalId);
                                            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                            setTimeout(() => {
                                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: responseStat.data.next.config.error });
                                                dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                                if(admin) {
                                                    navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Error')
                                                } else {
                                                    navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Error')
                                                }
                                            }, 2500);
                                        }
                                    }
                                })
                                    .catch(error => {
                                        clearInterval(refreshIntervalId);
                                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                                        setTimeout(() => {
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                                            genericErrorHandler(error)
                                        }, 2500);
                                    })
                            }
                            var refreshIntervalId = setInterval(checkOutStatus, 5000);
                        }).catch(error => {
                            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                            setTimeout(() => {
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                                genericErrorHandler(error)
                            }, 2500);
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                        setTimeout(() => {
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                            genericErrorHandler(error)
                        }, 2500);
                    })
            })
            .catch((error) => {
                dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                setTimeout(() => {
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                    dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                    genericErrorHandler(error)
                }, 2500);
            })
    }
}


export const checkOutConfirm = (id: string, cvv: string, productName: string, user: string, originalParams: any, navigate: any, countryId: any, admin: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
        dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: true });
        axios({
            method: 'POST',
            url: netCheckOutConfirm(id),
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: cvv ? `${cvv}` : null
        }).then(responseCon => {
            const checkOutStatus = () => {
                axios(netCheckOutStatus(id)).then(responseStat => {
                    if (responseStat.data.status === "FINISHED" || responseStat.data.status === "PENDING_REQUEST" || responseStat.data.status ===  "INCOMPLETED_PENDING_REQUEST") {
                        clearInterval(refreshIntervalId);
                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                        dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: true });
                        setTimeout(() => {
                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_PRODUCT, payload: productName });
                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_USER, payload: user });
                            dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                            if(admin) {
                                navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Exito')
                            } else {
                                navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Exito')
                            }
                            axios.get(getInsuredUser())
                                .then(response => {
                                    dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                                }).catch(error => {
                                });
                        }, 2500);
                    } else {
                        if (responseStat.data.status === "FAILED_EMISSION") {
                            clearInterval(refreshIntervalId);
                            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                            setTimeout(() => {
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: responseStat.data.next.config.error });
                                dispatch({ type: CHECK_OUT_CONFIRM_DATA, payload: responseStat.data });
                                if(admin) {
                                    navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Error')
                                } else {
                                    navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Error')
                                }
                            }, 2500);
                        }
                    }
                })
                    .catch(error => {
                        clearInterval(refreshIntervalId);
                        dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
                        dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
                        setTimeout(() => {
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                            dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                            genericErrorHandler(error)
                        }, 2500);
                    })
            }
            var refreshIntervalId = setInterval(checkOutStatus, 5000);
        }).catch(error => {
            dispatch({ type: CHECK_OUT_CONFIRM_START, payload: false });
            dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: true });
            setTimeout(() => {
                dispatch({ type: CHECK_OUT_CONFIRM_ERROR, payload: false });
                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: '' });
                genericErrorHandler(error)
            }, 2500);
        });
    }
}

export const tipoEditChanged = (value: number) => {
    return {
        type: TIPO_TARJETA_ID,
        payload: value
    };
};

export const bancoEditChanged = (value: number) => {
    return {
        type: BANCO_TARJETA_ID,
        payload: value
    };
};

export const dniTarjetaChanged = (value: string) => {
    return {
        type: DNI_TARJETA,
        payload: value
    };
};

export const titularTarjetaChanged = (value: string) => {
    return {
        type: TITULAR_TARJETA,
        payload: value
    };
};

export const fechaTarjetaChanged = (text) => {
    return (dispatch) => {
        let newText = text;
        if (text.length >= 3 && text[2] !== '/') {
            newText = text[0] + text[1] + '/' + text.slice(2);
        }
        dispatch({ type: FECHA_TARJETA, payload: newText });
    };
};

export const numeroTarjetaChanged = (text: string, cardBrandTypeControl: string) => {
    return (dispatch) => {
        let newText = text;
        if (cardBrandTypeControl !== "American Express") {
            if (text.length === 5 && text[4] !== ' ') {
                newText = text.substr(0, 4) + ' ' + text[4];
            }
            if (text.length === 10 && text[9] !== ' ') {
                newText = text.substr(0, 9) + ' ' + text[9];
            }
            if (text.length === 15 && text[14] !== ' ') {
                newText = text.substr(0, 14) + ' ' + text[14];
            }
        } else {
            if (text.length === 5 && text[4] !== ' ') {
                newText = text.substr(0, 4) + ' ' + text[4];
            }
            if (text.length === 12 && text[11] !== ' ') {
                newText = text.substr(0, 11) + ' ' + text[11];
            }
        }
        dispatch({ type: NUMERO_TARJETA, payload: newText });
    };
};

export const resetDataOnEnter = () => {
    return (dispatch: any) => {
        dispatch({ type: TIPO_TARJETA_ID, payload: -1 });
        dispatch({ type: BANCO_TARJETA_ID, payload: -1 });
        dispatch({ type: NUMERO_TARJETA, payload: '' });
        dispatch({ type: FECHA_TARJETA, payload: '' });
        dispatch({ type: TITULAR_TARJETA, payload: '' });
        dispatch({ type: DNI_TARJETA, payload: '' });
        dispatch({ type: CVV_TEXT_INPUT, payload: '' });
    }
}

export const fetchBrands2 = (pais: number) => {
    return async (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        try {
            const response = await axios.get(getBrands())
            let arrayBankTypes = response.data;
            arrayBankTypes.map((a: { codigo: number, descripcion: string }) => {
                a.descripcion = a.descripcion.replace(' MP', '');
            })
            if (pais === 2) {
                arrayBankTypes = arrayBankTypes.filter(a => a.descripcion !== 'CABAL')
                arrayBankTypes = arrayBankTypes.filter(a => a.descripcion !== 'CENCOSUD')
            }
            let objBank = {};
            objBank['arrayBankTypes'] = arrayBankTypes;
            dispatch({ type: FETCH_BANK_ARRAY, payload: objBank });
        } catch (error) {
            genericErrorHandler(error)
        } finally {
            dispatch({ type: LOADING, payload: false });
        }

    };
};

export const savePaymentMethod = (checkoutId, paymentInfo) => {
        return axios({
            url: netCheckOutPaymentFinish(checkoutId),
            method: 'put',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
            data: paymentInfo
        }).then(checkout =>  {
            return checkout.data;
        });
};

export const saveCbuCheckout = (userId: number, cbuText: string) => {
    const data = {
        'accountBankId': cbuText
    }
    return axios({
        url: netSaveCbuCheckout(userId),
        method: 'post',
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json'
        },
        data: data
    });
}

export const addPatChile = (data: any, userId: any ) => {
    return axios.post(netAddPatChile(userId), data).then((x) => x);
};

export const addCard2 = ({ brandCardId, dniNumber, dniTypeId, expiracion, issuerCardId, nroTarjeta, titular}, id: string, userId: any ) => {
        const data = {
            'brandCardId': brandCardId,
            'dniNumber': dniNumber,
            'dniTypeId': dniTypeId,
            'expiracion': expiracion,
            'issuerCardId': issuerCardId,
            'nroTarjeta': nroTarjeta,
            'titular': titular
        }
        return axios.post(postCreditCard(userId), data).then((x) => x);
};

export const checkOutModifyStep = (originalData: any, goToStep: string, writeStatus: string) => {
    return (dispatch: any) => {
        let aux = JSON.parse(JSON.stringify(originalData))
        aux.steps.map((a: any) => {
            if (a.type === goToStep) {
                a.status = writeStatus
            }
        })
        dispatch({ type: CHECKOUT_DATA, payload: aux });
    }
}

export const getCheckoutLink = (id: string, email: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: '', state: false } });
        const data = {
            "checkoutId": id,
            "type": email
        }
        axios({
            url: netCheckoutLink(),
            method: 'post',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: data
        }).then(response => {
            dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: 'Link enviado correctamente', state: true } });
            dispatch({ type: LOADING, payload: false });
        }).catch(error => {
            dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: '', state: false } });
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const setStepThree = (value: string) => {
    return {
        type: STEP_TRES,
        payload: value
    };
}

export const sendUbiPagosData = (id: string, productName: string, user: string, data: any, originalParams: any, navigate: any, countryId: any, admin: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netSendUbiPagosData(),
            method: 'post',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: data
        }).then(response => {
            axios({
                method: 'POST',
                url: netCheckOutConfirm(id),
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                },
            }).then(responseCon => {
                const checkOutStatus = () => {
                    axios(netCheckOutStatus(id)).then(responseStat => {
                        if (responseStat.data.status === "FINISHED" || responseStat.data.status === "PENDING_REQUEST" || responseStat.data.status ===  "INCOMPLETED_PENDING_REQUEST") {
                            clearInterval(refreshIntervalId);
                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_PRODUCT, payload: productName });
                            dispatch({ type: CHECK_OUT_CONFIRM_SUCCESS_USER, payload: user });
                            dispatch({ type: LOADING, payload: false });
                            if(admin) {
                                navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Exito')
                            } else {
                                navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Exito')
                            }
                            axios.get(getInsuredUser())
                                .then(response => {
                                    dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                                }).catch(error => {
                                });
                        } else {
                            if (responseStat.data.status === "FAILED_EMISSION") {
                                clearInterval(refreshIntervalId);
                                dispatch({ type: CHECK_OUT_CONFIRM_ERROR_MESSAGE, payload: responseStat.data.next.config.error });
                                dispatch({ type: LOADING, payload: false });
                                if(admin) {
                                    navigate(GetCountryUrl(countryId) + 'checkout-fin-admin?estado=Error')
                                } else {
                                    navigate(GetCountryUrl(countryId) + 'checkout-fin?estado=Error')
                                }
                            }
                        }
                    })
                        .catch(error => {
                            clearInterval(refreshIntervalId);
                            dispatch({ type: LOADING, payload: false });
                            genericErrorHandler(error)
                        })
                }
                var refreshIntervalId = setInterval(checkOutStatus, 5000);
            }).catch(error => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            });
        }).catch(error => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
        });
    }
}

export const actionSetPolicyHolder = (checkoutId: string, userId: number, admin: boolean, navigate: any, countryId: number, userData: any) => {
    return (dispatch: any) => {
        axios.put(netCheckOutPolicyHolder(checkoutId, userId))
            .then((response) => {
                dispatch({ type: USER_DATA, payload: userData });
                dispatch({ type: CHECKOUT_DATA, payload: response.data });
                const adminN = admin ? '&admin=True' : ''
                navigate(GetCountryUrl(countryId) + 'checkout?checkoutId=' + checkoutId + adminN, { state: { adminSelectedUser: true } })
            })
            .catch((error) => {
                genericErrorHandler(error)
            })
    }
}

//CARFACIL

export const getDataForNewUserArgCarfacil = (checkOutData: any, setProvincia: any, setLocalidad: any, setCodigoPostal: any, setActividadList: any, setSexoList: any, setEstadoCivilList: any, setSituaciónIVA: any, setSexo: any, setNumeroDocumento: any, setCuitTwo: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netCarfacilUserData(checkOutData?.offer?.product?.categoryId, checkOutData?.offer?.quoteId))
            .then((response) => {
                setProvincia(response?.data?.province)
                setLocalidad({ descripcion: response?.data?.location, codigo: response?.data?.idLocalidad})
                setCodigoPostal(response?.data?.postalCode?.toString())
                axios.get(netCarfacilAvtividades())
                    .then((response) => {
                        setActividadList(response?.data?.content)
                        axios.get(getSexTypes()).then(responseSexos => {
                            setSexoList(responseSexos.data);
                            axios.get(getCivilTypes()).then(responseEstadoCivil => {
                                setEstadoCivilList(responseEstadoCivil.data);
                                axios.get(netGetQuoteQuestions(checkOutData?.offer?.product?.categoryId, checkOutData?.offer?.quoteId))
                                    .then((response) => {
                                        setSituaciónIVA(response.data?.previousQuestions?.filter((a: any) => a.questionId === "37_ivaafip")[0]?.answerId)
                                        setSexo(response.data?.previousQuestions?.filter((a: any) => a.questionId === "38_genero")[0]?.answerId?.replace(/"/g, ''))
                                        setNumeroDocumento(response.data?.previousQuestions?.filter((a: any) => a.questionId === "41_documentinsured")[0]?.answerId)
                                        setCuitTwo(response.data?.previousQuestions?.filter((a: any) => a.questionId === "41_documentinsured")[0]?.answerId)
                                        dispatch({ type: LOADING, payload: false });
                                    })
                                    .catch((error) => {
                                        dispatch({ type: LOADING, payload: false });
                                        genericErrorHandler(error)
                                    })
                            })
                            .catch((error) => {
                                dispatch({ type: LOADING, payload: false });
                                genericErrorHandler(error)
                            })
                        })
                        .catch((error) => {
                            dispatch({ type: LOADING, payload: false });
                            genericErrorHandler(error)
                        })
                    })
                    .catch((error) => {
                        dispatch({ type: LOADING, payload: false });
                        genericErrorHandler(error)
                    })
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const actionCheckUserExistance = (numeroDocumento: any, setPresetUserData: any, setLoadingPresetData: any, setNotEditable: any, setPreExistingUserId: any) => {
    setLoadingPresetData(true)
    axios.get(netCheckUserExistance(numeroDocumento))
        .then((response) => {
            setPresetUserData(response.data)
            setPreExistingUserId(response.data?.id)
            setNotEditable(true)
            setLoadingPresetData(false)
        })
        .catch((error) => {
            setPresetUserData(null)
            setPreExistingUserId(null)
            setNotEditable(false)
            setLoadingPresetData(false)
        })
}

export const getDataFromQuote = (checkoutData: any, setSituaciónIVA: any, setSexo: any) => {
    axios.get(netGetQuoteQuestions(checkoutData?.offer?.product?.categoryId, checkoutData?.offer?.quoteId))
        .then((response) => {
            setSituaciónIVA(response.data?.previousQuestions?.filter((a: any) => a.questionId === "37_ivaafip")[0]?.answerId)
            setSexo(response.data?.previousQuestions?.filter((a: any) => a.questionId === "38_genero")[0]?.answerId?.replace(/"/g, ''))
        })
        .catch((error) => {
        })
}