import React, { useState } from 'react'
import './FormFieldPhoto.css'
import FormFieldPhotoItem from './FormFieldPhotoItem/FormFieldPhotoItem';

const FormFieldPhoto = (props: any) => {
    /**
     * Almacenamos las imagenes subidas hasta el momento.
     */
    const [status, setStatusState] = useState<any>(null)

    const updateIternalStatus = (data: string, index: number) => {
        let res: any = []
        if(status) res = status
        res[index] = data;
        setStatusState(res)
        const urlWithOutQueryParmas: string[] = res?.map((url: any) => url?.split("?")[0]);

        props.onUpdate(urlWithOutQueryParmas);
    };

    console.log(props)

    return (
        <div className="FormFieldPhoto-container">
            <p className="FormFieldPhoto-container-description bold">{props.data?.display?.name}&nbsp;<ul>*</ul></p>
            <div className="FormFieldPhoto-container-list">
                {props.data?.input?.presignedUrl?.map((a: any, index: number) => (
                    <FormFieldPhotoItem
                        key={index}
                        a={a}
                        index={index}
                        data={props.data}
                        onUpdate={updateIternalStatus}
                    />
                ))}
            </div>
        </div>
    )
}

export default FormFieldPhoto;